import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Features from "components/features/ThreeColWithSideImage";
import MainFeature from "components/features/TwoColWithButton";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating";
import FAQ from "components/faqs/SingleCol";
import GetStarted from "components/cta/GetStarted";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import Hero from "components/hero/TwoColumnWithPrimaryBackground"
import AboutUs from "components/cards/ProfileThreeColGrid"
// import  { ReactComponent as InstaIco} from  "images/instagram.svg"
import {RiInstagramLine as InstaIco} from "react-icons/ri"
import ShieldIconImage from "images/shield-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <div id="home">
      <Hero 
      roundedHeaderButton={true} 
      heading="Get your digital service loops done with our bugs."
      description="We provide services related to graphic design, digital marketing, videos and animation, writing and translation and IT solutions."
      primaryButtonText="Get The Quote"
      primaryButtonUrl="mailto:team@loopsbug.com"
      />
      </div>
      <hr/>
      <div id="services">
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Services.</HighlightedText> at one place.
          </>
        }
        description={""}
        cards={
          [
            
              {
                imageSrc: ShieldIconImage,
                title: "Digital Marketing",
                description: "we are expertised in industry leading digital marketing services"
              },
              {
                imageSrc: ShieldIconImage,
                title: "SEO Optmisation",
                description: "rank on first page of google. Isn't it cool ?"
              },
              {
                imageSrc: ShieldIconImage,
                title: "Graphics Design",
                description: "Design your logo, ads, posters an illustrations for your products."
              },
              {
                imageSrc: ShieldIconImage,
                title: "Content Creation",
                description: "We write blogs , we create all kind of conteant as per your requirements."
              },
              {
                imageSrc: ShieldIconImage,
                title: "Video Editing",
                description: "Hire professional editors from our team of experts ."
              },
              {
                imageSrc: ShieldIconImage,
                title: "Website / App",
                description: "Increase your digital presence with having your website / app."
              },
              
          ]
        }
        

      />

      </div>
      <hr/>
      <div>
      <MainFeature
        subheading={<Subheading>Fast & Relible Service</Subheading>}
        description={<>
        Loopsbug is a fast and reliable digital marketing agency that provides comprehensive solutions for businesses looking to enhance their online presence. Our team of experts is dedicated to delivering innovative and effective marketing strategies that drive growth and success for our clients. With a focus on speed and reliability, we work closely with you to understand your unique needs and goals, and tailor our services to meet your specific requirements. Whether you're looking to improve your search engine rankings, boost your social media following, or run a successful email marketing campaign, Loopsbug has the expertise and experience to help you succeed.
        </>}
        imageSrc={"/h1.jpeg"}
        imageBorder={true}
        imageDecoratorBlob={true}
        primaryButtonText={"Get Quote"}
        primaryButtonUrl={"mailto:team@loopsbug.com"}

      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        heading={<>We belive in accuracy and best quality</>}
        description={
          <>At Loopsbug, we believe that quality work is the foundation of a successful digital marketing campaign. That's why we strive to deliver the highest quality solutions to our clients, every time. Our team of experts is dedicated to staying up-to-date with the latest trends and technologies in the digital marketing industry, and leverages this knowledge to provide cutting-edge solutions that deliver real results. Whether you're looking to improve your website's user experience, increase your brand's online visibility, or drive more conversions, Loopsbug has the expertise and experience to deliver quality work that meets your specific goals. </>
        }
        imageSrc={"/h2.jpeg"}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        primaryButtonText={"Get Quote"}
        primaryButtonUrl={"mailto:team@loopsbug.com"}
      />
     </div>
     <hr/>
     <div id="about-us">
        
      <AboutUs 
      subheading="About Us"
      heading="Get your digital service loops done with our bugs."
      description="We provide services related to graphic design, digital marketing, videos and animation, writing and translation and IT solutions."
      cards={[
        {
          imageSrc: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
          position: "Co - Founder",
          name: "Atharv mandape",
          links: [
            {
              url: "https://instagram.com/atrv018?igshid=ZDdkNTZiNTM=",
              icon: InstaIco,
            },
            
          ],
        },
        {
          imageSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
          position: "Co - Founder",
          name: "Sanket patil ",
          links: [
            {
              url: "https://instagram.com/sp_rajput_264?igshid=YmMyMTA2M2Y=",
              icon: InstaIco,
            }
          ],
        }
      ]}
      />
     </div>
     {/* <hr/> */}
     {/* <div>
     <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        description=""
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      />
      </div> */}
      <hr/>
      <div>
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "How can we cantact to your team ?",
            answer:
              <>You can get our tean email . Here is our email address - <a href="mailto:team@loopsbug.com">team@loopsbug.com</a></>
          },
         
        ]}
      />
      </div>
      <div id="contact">
      <GetStarted
      primaryLinkUrl="mailto:team@loopsbug.com"
      />
      
     </div>
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
}
